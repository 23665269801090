@import '~swiper/css/swiper.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&display=swap');

.no-break {
  white-space: nowrap;
}

html,
body,
#root {
  height: 100%;
}

body {
  color: #636363;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

*{
  font-family: 'Montserrat', sans-serif !important;
}
.titleShop {
  font-family: 'Quicksand', sans-serif !important;
}
.icon-w {
  background: #09ce0d;
  color: #fff;
  font-size: 30px;
  padding: 4px;
  border-radius: 100%;
  width: 34px !important;
  height: 34px;
}

.icon-p {
  background: #0673f8;
  color: #fff;
  font-size: 30px;
  padding: 7px;
  border-radius: 100%;
  width: 32px !important;
  height: 32px;
}

.icon-e {
  background: #fbd123;
  color: #fff;
  font-size: 30px;
  padding: 6px;
  border-radius: 100%;
  width: 34px !important;
  height: 34px;
}
